import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Discussion with IBM Watson, Volley, and Microsoft`}</h2>
    <p>{`On Tuesday, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/?utm_source=medium&utm_medium=blog&utm_campaign=event&utm_content=sept19_wrapup"
      }}>{`Dashbot`}</a>{` hosted a panel of voice technology leaders who discussed how people should be thinking about voice products and what to include when building on different platforms.`}</p>
    <p>{`Speakers included:`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/mlchild/"
      }}>{`Max Child`}</a>{` — CEO `}{`&`}{` co-founder, `}<a parentName="p" {...{
        "href": "https://volleythat.com/"
      }}>{`Volley`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://medium.com/@anamitag"
      }}>{`Anamita Guha`}</a>{` — Product Manager, `}<a parentName="p" {...{
        "href": "https://www.ibm.com/watson"
      }}>{`IBM Watson`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/priya-rajan-a3a5992/"
      }}>{`Priya Rajan`}</a>{` — Senior Program Manager, `}<a parentName="p" {...{
        "href": "https://www.microsoft.com/en-us/"
      }}>{`Microsoft`}</a></p>
    <h2><strong parentName="h2">{`What are interesting use cases you’ve seen for voice products?`}</strong></h2>
    <p><strong parentName="p">{`PR — Microsoft:`}</strong>{` Re-inventing productivity in a completely different way and making your life better is really interesting. Doctors use speech recognition so that they can make better use of their time instead of spending hours note taking. The most interesting use case is accessibility scenario. We recently released `}<a parentName="p" {...{
        "href": "https://www.onenote.com/learningtools"
      }}>{`OneNote Learning Tools`}</a>{` and kids with dyslexia took speech to a completely different level.`}</p>
    <p><strong parentName="p">{`AG — IBM Watson:`}</strong>{` I’ve seen cool applications for productivity to make better use of meetings. Also, applications to retail and connection to IoT devices. For example, there’s a mirror that reads inventory and updates to a database. Another really cool application is with public services like coal mining where you’re able to communicate a temperature and have it adjust when you can’t use your hands to do that.`}</p>
    <p><strong parentName="p">{`MC — Volley:`}</strong>{` I really like consumer and gaming applications, like `}<a parentName="p" {...{
        "href": "https://developer.ibm.com/dwblog/2017/watson-voice-commands-star-trek/"
      }}>{`Star Trek: Bridge Crew`}</a>{`. We’re really excited about a future that’s controlled by voice.`}</p>
    <h2><strong parentName="h2">{`What industries do you think have the most potential for voice development?`}</strong></h2>
    <p><strong parentName="p">{`AG — IBM Watson:`}</strong>{` Military, NASA, or any sort of risky situation could be commanded by voice. I could see a world where while you’re sleeping, your alarm clock adjusts to wake you when the traffic isn’t heavy. Then on your way out of the house, your fridge reminds you to buy milk.`}</p>
    <p><strong parentName="p">{`MC — Volley:`}</strong>{` Additionally, education is really exciting because kids are some of the most prolific users of these devices. The learning of math, history, language will be revolutionized once these topics are fully supported on these platforms.`}</p>
    <h2><strong parentName="h2">{`What types of capabilities and functionality should developers consider including when building voice products to increase engagement?`}</strong></h2>
    <p><strong parentName="p">{`MC — Volley:`}</strong>{` There are basic mistakes you could make when first building a voice app. On one side of the spectrum, you assume you could converse with users like a human and having cross-talk with a bunch of people interacting with it. On the other end of the spectrum, you build to a product that is too simple and uninteresting.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`You need to balance the level of engagement and entertainment but also narrow the domain of what users can say to it so that you can guarantee the device recognizes what the user says at least 98% of the time.`}</em></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`— Max Child, Volley`}</em></p>
    </blockquote>
    <p>{`A lot of people think 85% is good enough and it’s definitely not. You could also make an application that’s boring, but works 100% of the time, which isn’t great either.`}</p>
    <p><strong parentName="p">{`AG — IBM Watson:`}</strong>{` Context is very important when deciding which platform to build on. If it’s a conversation in VR and you have the ability to put a virtual entity in front of users, the way you build a bot is going to be different.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`You need to understand the platform you’re building on, who you’re building it for, and how they are going to use it.`}</em></p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`— Anamita Guha, IBM Watson`}</em></p>
    </blockquote>
    <p>{`Having a fallback mechanism is crucial to redirecting the user’s focus on what your product can accomplish for them and what it can’t. For example, if you built a voice-enabled car and the user says, “Hey car, mow my lawn!” you need to remind the user of the platform’s goals.`}</p>
    <p><strong parentName="p">{`PR — Microsoft:`}</strong>{` Ease of use is key to increasing engagement. When we first built Cortana, there was a big barrier for creating an account and the types of permissions we asked users for so we adjusted. Now, if you’re just asking for the weather, we don’t require that you login. However, if you ask about weather in a specific place and it requires access to location settings, we’ll need you to authenticate.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Before you put your product in anyone’s hands, make sure you put your best foot forward because first impressions make a big difference. When you put it out, always have a mechanism for you to quickly react.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`— Priya Rajan, Microsoft`}</p>
    </blockquote>
    <p><strong parentName="p">{`AG — IBM Watson:`}</strong>{` Last thing to remember is that people are using full sentences and slang so make sure to incorporate that into the logic as you’re building your bot.`}</p>
    <h2><strong parentName="h2">{`What recommendations do you have for increasing discoverability?`}</strong></h2>
    <p><strong parentName="p">{`MC — Volley:`}</strong>{` Ask users for reviews. If you only have hundreds of users a week and you convert 15 of those people to write real, genuine reviews, you’ll probably be on the app store’s radar. If you get 15 reviews in 3 days, the app store will notice that and just might try out your Skill. And if they like it, they might bury it somewhere on the front page. It’s a little bit of a snowball effect as you’re building momentum.`}</p>
    <p><strong parentName="p">{`AG —IBM Watson:`}</strong>{` If you’re designing for VR, having a visual cue is helpful. Taking advantage of that capability and incorporate something flashing or something users can touch to activate it. It’s really important to have something that brings you there so users know what to do.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Being aware of wake words, the word that will activate the device, and making sure that it’s a word or words that everyone can understand and express easily.`}</p>
    </blockquote>
    <p><strong parentName="p">{`PR — Microsoft:`}</strong>{` Make your product available on as many devices as possible. If I can have my assistant anywhere all the time, people will want to use that assistant all the time.`}</p>
    <h2><strong parentName="h2">{`What KPIs are important to you and your company?`}</strong></h2>
    <p><strong parentName="p">{`PR — Microsoft:`}</strong>{` Word error rate which is the accuracy of our speech recognition. We measure it by scenario so if you’re trying to play music, we look at how well our music domains are functioning. Or another example is if you’re setting an alarm, were we able to distinguish between AM and PM?`}</p>
    <p>{`End-to-end user-perceived latency is another big KPI for us. For example, when you ask Cortana to play music, how long does it take her to find the music and play it for you?`}</p>
    <p>{`High-level metrics we also look at include: the number of interactions, retention, answer quality (are people satisfied with the answer Cortana gave), and user sentiment (satisfied and unsatisfied customers).`}</p>
    <p><strong parentName="p">{`AG — IBM Watson:`}</strong>{` If it’s a customer service bot, we’ll want to target as little interaction in as little time as possible. If it’s something that’s trying to engage you, we want to aim for a long interaction. It depends on what’s being built.`}</p>
    <p><strong parentName="p">{`MC — Volley:`}</strong>{` We care about longer sessions, more sessions, and higher retention. They can always shut down and walk away for 30 seconds and never come back.`}</p>
    <h2><strong parentName="h2">{`What do you do to educate users about the space?`}</strong></h2>
    <p><strong parentName="p">{`MC — Volley:`}</strong>{` People who have Amazon Echos don’t know that they could enable really cool games, so that’s the first step — showing people how to enable Skills.`}</p>
    <p><strong parentName="p">{`AG — IBM Watson:`}</strong>{` I learn a lot from events. People who work at competing companies are still working together to move the needle and this industry forward and they do so by talking to and teaching each other.`}</p>
    <p><strong parentName="p">{`PR — Microsoft:`}</strong>{` A lot of people want to be in this space. Developers want to be in this space. People are eager to get into this space and they want to figure how to get into it. 10 years ago, real production applications couldn’t be built. We’ve come a long way where we can actually say 95% of the cases work. We need to make sure that we continue building on that and making it more and more useful for people.`}</p>
    <hr></hr>
    <p>{`Join Dashbot at `}<strong parentName="p">{`Hackathon: Bot or Not`}</strong>{` on November 4–5 to build messaging and voice bots. Find your team and register `}<a parentName="p" {...{
        "href": "http://bit.ly/BotHackathon"
      }}>{`here`}</a>{`!`}</p>
    <hr></hr>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`You can view detailed analytics `}{`&`}{` competitive market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment `}{`&`}{` demographics, and add human-in-the-loop to your bot — all in real time.`}</p>
    <p>{`We natively support Facebook Messenger, Twitter, Amazon Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface. Sign up for free at `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/?utm_source=medium&utm_medium=blog&utm_campaign=event&utm_content=sept19_wrapup"
      }}>{`www.dashbot.io`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      